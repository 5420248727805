<!--
	This is the actions page, it uses the dashboard layout in:
	"./layouts/Dashboard.vue" .
	It uses the CardActionsTable component in:
	"./components/Cards/CardActionsTable.vue" .
	It gets the data from the store in:
	"./store/actions.js" .
 -->

<template>
	<div>
		<a-row  :gutter="24" type="flex">
			<a-col  :span="24" class="mb-24">
				<CardActionsActions
					:data="table1Data"
					:columns="table1Columns"></CardActionsActions>

			</a-col>

		</a-row>
		<!-- Actions Table -->
		<a-row :gutter="24" type="flex">

			<!-- Actions Table Column -->
			<a-col :span="24" class="mb-24">

				<!-- Actions Table Card -->
				<CardActionsTable
					:v-if="actionTableVisible"
					:data="table1Data"
					:columns="table1Columns"
				></CardActionsTable>
				<!-- / Actions Table Card -->

			</a-col>
			<!-- / Actions Table Column -->

		</a-row>
		<!-- / Actions Table -->

	</div>
</template>

<script>

	// "Actions" table component.
	import CardActionsTable from '../components/Cards/CardActionsTable.vue';
	import CardActionsActions from '../components/Cards/CardActionsActions.vue';
	import { mapActions } from 'vuex'
	import { mapState } from 'vuex'

	// "Actions" table list of columns and their properties.
	const table1Columns = [
		{
			title: 'Project',
			dataIndex: 'item_w_key',
			scopedSlots: { customRender: 'item_w_key' },
			sorter: (a, b) => a.item_w_key.item.localeCompare(b.item_w_key.item), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},


		{
			title: 'Assignee',
			dataIndex: 'owner',
			scopedSlots: { customRender: 'owner' },
			sorter: (a, b) => a.owner.apartment.localeCompare(b.owner.apartment), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Status',
			scopedSlots: { customRender: 'status' },
			sorter: (a, b) => a.status.localeCompare(b.status), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Details',
			dataIndex: 'details',
			class: 'font-semibold text-muted text-sm details',
			sorter: (a, b) => a.details.localeCompare(b.details), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Comments',
			dataIndex: 'num_comments_w_key',
			scopedSlots: { customRender: 'num_comments_w_key' },
			sorter: (a, b) => a.num_comments_w_key.num_comments.localeCompare(b.num_comments_w_key.num_comments), // this is for string comparison
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},
		{
			title: 'Due Date',
			dataIndex: 'due_date',
			class: 'font-semibold text-muted text-sm',
			width: 120,
			sorter: (a, b) => new Date(a.due_date) - new Date(b.due_date), // assuming the 'date' field contains a Date object
			sortDirections: ['descend', 'ascend'], // Specifies the order of sorter
		},


		{
			title: '',
			scopedSlots: { customRender: 'actionsBtn' },
		},
	];






	export default ({
		components: {
		    CardActionsTable,
			  CardActionsActions
		},
		data() {
			return {
				actionTableVisible: false,
				// Associating "Actions" table columns with its corresponding property.
				table1Columns: table1Columns,

			}
		},
		computed: {
			...mapState({
				table1Data: state => state.actions.actions,
			})
		},
		methods: {
			...mapActions({
				getActions: 'actions/getActions',
			}),
		},
		async mounted() {
			await this.getActions();
			this.actionTableVisible = true
			window.analytics.page('Actions')
		},
	})

</script>

<style lang="scss">

.details {
    white-space: pre-wrap;
}
</style>
